<template>
  <div>
    <b-card>
      <AgGrid
        ref="CarrierGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="((Role != 'readonly' && Role != 'readonly admin') && (Permission.CreateBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Create a new carrier'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="CreateNewCarrier()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">New Carrier</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateCarrierModal"
      ref="CreateCarrier"
      title="New Carrier"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="headerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Carrier Name"
                rules="required|max:100"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Carrier Name:</b></i></label>
                  <b-form-input
                    id="Carrier"
                    v-model="CarrierDetail.Carrier"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="Carrier"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="SCAC"
                rules="required|max:4"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>SCAC:</b></i></label>
                  <b-form-input
                    id="SCAC"
                    v-model="CarrierDetail.SCAC"
                    v-uppercase
                    :state="errors.length > 0 ? false:null"
                    placeholder="SCAC"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewCarrier()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Booking/BookingCarrierViewBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  components: {
    AgGrid,
  },
  data() {
    return {
      required,
      aggrid: [],
      CarrierId: '',
      CarrierDetail: {
        Carrier: '',
        SCAC: '',
      },
      Permission: {},
      Role: '',
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.aggrid = this.InitGridInstance('Carrier')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 100,
        minWidth: 100,
        maxWidth: 100,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'Carrier', field: 'carrier', maxWidth: 180, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'SCAC', field: 'scac', maxWidth: 95, filter: 'agSetColumnFilter',
      },

    ]

    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.CarrierGrid.saveState('Carrier') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.CarrierGrid.saveState('Carrier') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.CarrierGrid.saveState('Carrier') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.CarrierGrid.saveState('Carrier') }
    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(CarrierId) {
      this.CarrierId = CarrierId
      this.$router.push(`/booking/carrier/detail/${CarrierId}`)
    },
    DoubleClickOpenPopup(params) {
      this.CarrierId = params.data.id
      this.$router.push(`/booking/carrier/detail/${params.data.id}`)
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadCarrierList()])
        .then(() => {
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
          this.LoadingEnd()
        })
    },
    LoadCarrierList() {
      axios.get('/booking/carrier')
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.CarrierGrid.ResetColumns()
        })
    },
    CreateNewCarrier() {
      this.$refs.CreateCarrier.show()
    },
    SaveNewCarrier() {
      this.$refs.headerRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          const CarriersModel = {
            Carrier: this.CarrierDetail.Carrier,
            SCAC: this.CarrierDetail.SCAC,
          }

          axios.post('/booking/carrier', CarriersModel)
            .then(response => {
              this.LoadingEnd()
              this.$refs.CreateCarrier.hide()
              this.DisplaySuccess('Carrier data has been saved.').then(() => {
                this.$router.push(`/booking/carrier/detail/${response.data}`)
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.$refs.CreateCarrier.hide()
              this.DisplayError('New carrier cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('New carrier cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    Delete(CarrierId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/booking/carrier/${CarrierId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Carrier has been removed.')
              .then(() => {
                // redirect
                this.LoadCarrierList()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Carrier cannot be removed.', `${e.response.data.StatusCode} / ${e.response.data.Message}`)
          })
        }
      })
    },
  },
}
</script>
